import React, { useState } from 'react'

const Button = ({ text, href }) => (
  <div className="dim">
    <a className="ba ph2 link" href={href}>
      {text} &#8599;
    </a>
  </div>
)

export const DataDescriptionModule = ({
  contents,
  classNameArticle,
  classNameFirstDiv,
  color,
  href,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <article className={`${classNameArticle} bg-pastel-${color} w-50-large`}>
      <div
        className={`${classNameFirstDiv} ${
          isOpen ? 'mv4' : 'mv0'
        } mw6-l ph4 h-100 flex-ns flex-column-ns`}
      >
        <div className="flex items-center justify-between">
          <div
            className={`${isOpen ? 'mv0' : 'mv4 mt5-ns'} mono normal lh-title f4 f3-ns`}
            style={{ maxWidth: '20rem' }}
          >
            {contents.title}
          </div>
          <div
            className={`b f4 pa3 dn-large relative ${isOpen ? 'arrow-animation' : ''}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="arrow-left-part" />
            <span className="arrow-right-part" />
          </div>
        </div>
        <div
          className={`overflow-hidden accordion-transition ${
            isOpen ? 'mh-500-small mt4' : 'mh-0-small'
          }`}
        >
          <div>{contents.subtitle}</div>
          <ul className="list pl0" style={{ minHeight: 200 }}>
            {contents.listItems.map((item, i) => (
              <li key={i} className={`h3 accurat-arrow-${color} flex`}>
                {item}
              </li>
            ))}
          </ul>
          <p>{contents.paragraph}</p>

          <Button text={contents.buttonText} href={href} />
        </div>
      </div>
    </article>
  )
}
