import React, { useRef, useEffect } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Wrapper } from './shared/Wrapper'
import { gatsbyWindow } from '../utils'

const MOBILE_BREAKPOINT = 480

gsap.registerPlugin(ScrollTrigger)

const featuredCaseStudies = [
  {
    name: 'Starbucks',
    color: '#86CDC3',
    thumbUrl: 'images/starbucks-thumb.jpg',
    url: '/work/starbucks',
  },
  {
    name: 'IBM',
    color: '#E5ECCD',
    thumbUrl: 'images/ibm-thumb.jpg',
    url: '/work/ibm-language',
  },
  {
    name: 'Google',
    color: '#FFEC81',
    thumbUrl: 'images/buildinghopes-thumb.jpg',
    url: '/work/buildinghopes',
  },
  {
    name: 'MoMA',
    color: '#F7BDC1',
    thumbUrl: 'images/moma-thumb.jpg',
    url: '/work/moma/',
  },
]

function showElement(element) {
  if (element) {
    element.classList.remove('hidden', 'dimmed')
    element.classList.add('visible')
  }
}

function dimElement(element) {
  if (element) {
    element.classList.remove('visible', 'hidden')
    element.classList.add('dimmed')
  }
}

function hideElement(element) {
  if (element) {
    element.classList.remove('visible', 'dimmed')
    element.classList.add('hidden')
  }
}

export const VerticalSlider = function VerticalSlider() {
  const scrollerRef = useRef(null)
  const imagesRef = useRef(null)
  const windowWidth = useWindowWidth()
  var isMobile = useRef(gatsbyWindow.innerWidth < MOBILE_BREAKPOINT)

  useEffect(() => {
    isMobile.current = gatsbyWindow.innerWidth < MOBILE_BREAKPOINT
    ScrollTrigger.refresh()
  }, [windowWidth])

  useEffect(() => {
    if (!scrollerRef || !imagesRef) return

    const scrollerContainer = scrollerRef.current
    const imagesContainer = imagesRef.current
    const scrollerContainerHeight = scrollerContainer.offsetHeight
    const imagesContainerHeight = imagesContainer.offsetHeight

    const labelsTl = scrollerContainer.querySelectorAll('.vertical-slider-texts .label')
    const imagesTl = scrollerContainer.querySelectorAll('.vertical-slider-images .image')

    const tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: scrollerContainer,
        // Note: activate for debug
        // markers: true,
        // pin the trigger element while active
        pin: true,
        // when the top of the trigger hits the top of the viewport
        start: () => {
          // if mobile, start from 0 to anchor image on top
          if (isMobile.current) return `0 0`
          else {
            const y = -(window.innerHeight - imagesContainerHeight) / 2
            return `${y} 0`
          }
        },
        // higher num slower anim
        end: '+=5000',
        scrub: 0.5,
      },
    })

    let labelY = isMobile.current
      ? scrollerContainerHeight - imagesContainerHeight
      : imagesContainerHeight + (window.innerHeight - imagesContainerHeight) / 2

    labelsTl.forEach((el, i) => {
      const labelHeight = labelsTl[i].getBoundingClientRect().height
      labelY -= labelHeight

      tl.from(el, {
        y: labelY,
        onStart: () => {
          const image = imagesTl[i]
          const label = labelsTl[i]
          const labelBefore = labelsTl[i - 1]
          showElement(image)
          dimElement(labelBefore)
          showElement(label)
        },
        onReverseComplete: () => {
          const imageBottom = imagesTl[i - 1]
          const labelBottom = labelsTl[i - 1]
          showElement(imageBottom)
          showElement(labelBottom)

          const imageTop = imagesTl[i]
          const labelTop = labelsTl[i]
          hideElement(imageTop)
          hideElement(labelTop)
        },
      })
    })
  }, [])

  return (
    <div className="mv6-ns">
      <Wrapper
        type="full"
        className="flex flex-column flex-row-ns justify-center ph0 ph4-ns"
        ref={scrollerRef}
      >
        <div className="f4 f3-ns mh4 mv4 mv0-ns ml0-ns mr6-ns flex flex-column order-1 order-0-ns">
          <div className="db mono f5 mb3 mb4-ns">WE WORK WITH</div>
          <div className="vertical-slider-texts">
            {featuredCaseStudies.map(({ name, url }) => (
              <a key={name} className="link underline-hover label" href={url}>
                {name}
              </a>
            ))}
            <span className="label">and many other companies</span>
            <div className="label">
              to create{' '}
              <a className="color-inherit no-underline underline-hover" href="/work/rctj">
                software
              </a>
              ,
            </div>
            <span className="label">revenue,</span>
            <div className="label">
              and{' '}
              <a
                className="color-inherit no-underline underline-hover"
                href="/work/knightfoundation"
              >
                digital experiences.
              </a>
            </div>
            <a className="link label f5 mt3 mt5-ns bb w-fit hover-black-60" href="/work">
              Discover more ↗
            </a>
          </div>
        </div>
        <div className="vertical-slider-images relative overflow-hidden mw-100" ref={imagesRef}>
          {featuredCaseStudies.map(({ name, url, thumbUrl }, i) => (
            <a
              key={name}
              href={url}
              className={`${name} image db absolute cover contain-ns w-100 h-100`}
              style={{
                background: `url(${thumbUrl}) no-repeat`,
              }}
            >
              {' '}
            </a>
          ))}

          <div
            className="other image absolute cover contain-ns w-100 h-100"
            key="saipem"
            style={{
              background: `url("images/saipem-thumb.jpg") no-repeat`,
            }}
          />
          <a
            className="image db absolute cover contain-ns w-100 h-100"
            key="davinci"
            href="/work/rtcj"
            style={{
              background: `url("images/davinci-thumb.jpg") no-repeat`,
            }}
          >
            {' '}
          </a>
          <div
            className="other image absolute cover contain-ns w-100 h-100"
            key="blackknight"
            style={{
              background: `url("images/blackknight-thumb.jpg") no-repeat`,
            }}
          />
          <a
            className="image absolute cover contain-ns w-100 h-100"
            key="knightfoundation"
            href="work/knightfoundation"
            style={{
              background: `url("images/knightfoundation-thumb.jpg") no-repeat`,
            }}
          >
            {' '}
          </a>
        </div>
      </Wrapper>
    </div>
  )
}
