const BARS_GAP = 25
const BAR_HEIGHT = 10
const BAR_WIDTH = 1
const APPROACH = 40
const JELLY = 88
const VISCOSITY = 0.15
const OPACITY_RADIUS = 300
const BAR_COLOR = '#999'

let canvas
let ctx
let osCanvas
let osCtx
let osBarCanvas
let osBarCtx
let center
let points = []
let startX, startY
let mouse
let width
let height

function getDistance(x1, y1, x2, y2) {
  return Math.hypot(x2 - x1, y2 - y1)
}

let timerId = null
let isStopped = false

class Point {
  constructor(x, y) {
    this.oX = x + BAR_HEIGHT
    this.oY = y + BAR_HEIGHT

    this.x = x + BAR_HEIGHT
    this.y = y + BAR_HEIGHT

    this.offsetX = 0
    this.offsetY = 0
  }

  update(mouse) {
    this.offsetX += (this.oX - this.x) / JELLY
    this.offsetY += (this.oY - this.y) / JELLY

    const distX = this.x - mouse.x
    const distY = this.y - mouse.y

    if (Math.sqrt(distX * distX + distY * distY) <= APPROACH) {
      const divisor = APPROACH / 10
      const angle = Math.atan2(distY, distX)

      this.offsetX += (Math.cos(angle) * APPROACH - distX) / divisor
      this.offsetY += (Math.sin(angle) * APPROACH - distY) / divisor
    }

    this.offsetX *= 1 - VISCOSITY
    this.offsetY *= 1 - VISCOSITY

    if (Math.abs(this.offsetX) < 0.001) this.offsetX = 0
    if (Math.abs(this.offsetY) < 0.001) this.offsetY = 0

    this.x += this.offsetX
    this.y += this.offsetY

    this.distanceFromMouse = getDistance(mouse.x, mouse.y, this.x, this.y)
  }
}

export function init(cv) {
  canvas = cv
  ctx = canvas.getContext('2d')

  createOffScreenCanvas()
  reset()
  draw()
}

export function reset() {
  const { width: w, height: h } = canvas.getBoundingClientRect()

  width = w
  height = h

  mouse = {
    x: width * 0.75,
    y: height * 0.35,
  }

  center = {
    x: Math.round(width),
    y: Math.round(height),
  }

  canvas.width = width
  canvas.height = height
  osCanvas.width = width
  osCanvas.height = height

  setStartPosition()
  createPoints()
  drawBar()
}

export function stop() {
  isStopped = true
}

function createOffScreenCanvas() {
  osCanvas = document.createElement('canvas')
  osCtx = osCanvas.getContext('2d')

  osBarCanvas = document.createElement('canvas')
  osBarCtx = osBarCanvas.getContext('2d')
}

function drawBar() {
  osBarCanvas.width = BAR_HEIGHT * 2
  osBarCanvas.height = BAR_HEIGHT * 2

  osBarCtx.clearRect(0, 0, BAR_HEIGHT * 2, BAR_HEIGHT * 2)
  osBarCtx.fillStyle = BAR_COLOR
  osBarCtx.fillRect(0, 0, BAR_WIDTH, BAR_HEIGHT)
}

function setStartPosition() {
  for (let i = center.x; i > 0; i = i - BARS_GAP) {
    startX = i - BAR_HEIGHT - BARS_GAP
  }

  for (let i = center.y; i > 0; i = i - BARS_GAP) {
    startY = i - BAR_HEIGHT - BARS_GAP
  }
}

function createPoints() {
  points = []

  for (let i = 0; i <= width + BARS_GAP; i += BARS_GAP) {
    for (let j = 0; j <= height + BARS_GAP; j += BARS_GAP) {
      const point = new Point(startX + i, startY + j)
      points.push(point)
    }
  }
}

function draw() {
  if (isStopped) {
    return
  }

  render()
  requestAnimationFrame(draw)
}

function render() {
  ctx.clearRect(0, 0, width, height)
  osCtx.clearRect(0, 0, osCanvas.width, osCanvas.height)

  points.forEach(point => {
    point.update(mouse)

    const dx = mouse.x - point.x
    const dy = mouse.y - point.y
    const angle = Math.atan2(dy, dx)

    osCtx.save()
    osCtx.translate(point.x, point.y)
    osCtx.rotate(angle)

    const normalizedDistance = Math.min(point.distanceFromMouse / (OPACITY_RADIUS * 2), 1)
    osCtx.globalAlpha = 1 - normalizedDistance

    osCtx.drawImage(osBarCanvas, 0, 0, BAR_HEIGHT * 2, BAR_HEIGHT * 2)
    osCtx.restore()
  })

  ctx.drawImage(osCanvas, 0, 0, width, height)
}

export const update = evt => {
  if (evt) {
    mouse = {
      x: evt.pageX,
      y: evt.pageY,
    }
  }

  if (timerId) {
    clearTimeout(timerId)
  }

  if (isStopped) {
    isStopped = false
    draw()
  }

  timerId = setTimeout(() => {
    isStopped = true
  }, 2000)
}
