import React from 'react'
import { SectionTwoThirdsRight } from './SectionTwoThirdsRight'

export const LeftSideParagraphModule = ({ contents, className, sectionClass='' }) => {
  const { title, paragraphs, href, link } = contents

  return (
    <div className={`${className} mw7-m mw8-l center-ns ph3`}>
      <SectionTwoThirdsRight className={sectionClass} label={title}>
        <div className="f4-ns" dangerouslySetInnerHTML={{ __html: paragraphs }} />
        {link && (
          <a
            className="link f6 underline mt3"
            style={{ textUnderlinePosition: 'under' }}
            href={href}
          >
            {link} &#8599;
          </a>
        )}
      </SectionTwoThirdsRight>
    </div>
  )
}
