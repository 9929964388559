import React, { useRef, useEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import { init, update, reset, stop } from '../utils/interactive-background'

const VISIBILITY_BREAKPOINT = 1024
let isActive = false

export function InteractiveBackground() {
  const canvas = useRef(null)
  const [width] = useWindowSize()

  useEffect(() => {
    if (!canvas.current) return

    if (width > VISIBILITY_BREAKPOINT) {
      if (isActive) {
        reset()
      } else {
        isActive = true
        init(canvas.current)
      }
      update()
    } else if (isActive) {
      stop()
      reset()
      isActive = false
    }
  })

  const onMove = evt => {
    if (isActive) {
      update(evt)
    }
  }

  return (
    <canvas id="background" ref={canvas} className="absolute w-100 h-100" onMouseMove={onMove} />
  )
}
