import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Wrapper } from '../components/shared/Wrapper'
import { InteractiveBackground } from '../components/InteractiveBackground'
import { DataDescriptionModule } from '../components/DataDescriptionModule'
import { LeftSideParagraphModule } from '../components/LeftSideParagraphModule'
import { FollowUsModule } from '../components/FollowUsModule'
import { Clients } from '../components/VerticalComponents'
import { VerticalSlider } from '../components/VerticalSlider'
export default class IndexPage extends React.Component {
  render() {
    const { location, data } = this.props
    const { dataDescription, ourApproach, workWithUs } = data.allHomeJson.nodes[0]

    return (
      <Layout type={'home'} location={location}>
        <div className="vh-100 w-100 absolute top-0 left-0">
          <InteractiveBackground />
        </div>
        <Wrapper
          type="full"
          vhfull
          uniqueName="heading"
          className="relative flex-ns flex-column justify-center pointer-events-none"
        >
          <h1 className="f-title lh-title pv0-ns pt3 ph3 ph4-m ph6-ns">
            Accurat
            <br />
            is a&nbsp;
            <div
              style={{ transform: 'matrix(0.99, 0, 0.11, 1, 0, 0)' }}
              className="w-fit dn dib-ns"
            >
              data visualization
            </div>
            <div
              style={{ transform: 'matrix(0.99, 0, 0.11, 1, 0, 0)' }}
              className="w-fit dib dn-ns"
            >
              data
            </div>
            <div
              style={{ transform: 'matrix(0.99, 0, 0.11, 1, 0, 0)' }}
              className="w-fit dib dn-ns"
            >
              visualization
            </div>
            <br />
            design and development studio.
            <br />
            <a
              className="db link f5 fw5 mt3 mt5-ns bb hover-black-60 pointer-events-all"
              href="/work"
            >
              Explore our work ↗
            </a>
          </h1>

          <div className="arrow-icon absolute bottom-2 mb6 mb5-ns w-100 tc">
            <svg width="51" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M30.824 17.88l-.912-.816-3.504 3.384V.528h-1.536v19.92l-3.504-3.384-.912.816 5.184 5.592 5.184-5.592z"
                fill="#111"
              />
            </svg>
          </div>
        </Wrapper>

        <VerticalSlider />

        <Wrapper type="full" className="flex flex-column flex-row-large ">
          <DataDescriptionModule
            classNameArticle="flex justify-end"
            classNameFirstDiv="pr5-ns pb5-ns"
            contents={dataDescription[0]}
            color="red"
            href="/data-driven-art-design"
          />
          <DataDescriptionModule
            classNameFirstDiv="pl5-ns  pb5-ns"
            contents={dataDescription[1]}
            color="blue"
            href="/enterprise-analytics"
          />
        </Wrapper>

        <Wrapper className="mv5-ns mv4">
          <Clients className="ph3" logos={logos} isBorderBottom />
          <LeftSideParagraphModule
            className="mv4 mt5-ns mb5-ns"
            sectionClass="pb4 pb5-ns bb b--light-gray"
            contents={ourApproach}
          />
          <LeftSideParagraphModule className="mv4 mt5-ns mb5-ns" contents={workWithUs} />
        </Wrapper>

        <FollowUsModule />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allHomeJson {
      nodes {
        dataDescription {
          buttonText
          listItems
          paragraph
          subtitle
          title
        }
        ourApproach {
          paragraphs
          title
        }
        workWithUs {
          paragraphs
          link
          title
          href
        }
      }
    }
  }
`

const logos = [
  'accenture',
  'bill-and-melinda-gates-foundation',
  'cerved',
  'ted',
  'fineco',
  'generali',
  'google',
  'gucci',
  'ibm',
  'iqvia',
  'jpmorgan-chase',
  'kpmg',
  'microsoft',
  'moma',
  'poste-italiane',
  'starbucks',
  'tim',
  'university-of-california-berkeley',
  'valentino',
]
